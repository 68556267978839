import React, { useState } from "react";
//import { MenuAlt3Icon } from '@heroicons/react/solid'
import { MdOutlineMenu } from "react-icons/md";
import logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import {BsArrowRightShort} from "react-icons/bs";

export const Navbar = ({ page }) => {
 

  const [open, setOpen] = useState(true);
  return ( 
    <>
      <div className="fixed  w-full top-0 left-0 border-b-4 border-[#ff725e] bg-white">
        <nav className="relative md:mx-12 py-3 w-full md:w-fit navbar navbar-expand-lg navbar-light  ">
          <div className="mx-4 md:px-2 md:flex items-center justify-between py-2 w-full ">
            <div className="flex justify-between items-center mr-8">

            <div className="w-4/12">
              <Link to={"/"}>
                <div>
                  <img src={logo} alt="logo" className="w-8/12 " />
                </div>
              </Link>
            </div>
            <div className="flex md:hidden cursor-pointer ">
              <MdOutlineMenu className="w-8 h-8 text-[#ff725e] " onClick={() => setOpen(!open)} />
            </div>
            </div>

            <div className={` md:flex z-[-1] md:z-auto md:static absolute bg-white w-full left-0 pb-5 md:pb-0 px-8 md:px-0  md:w-8/12 md:justify-end border-b-2 border-[#ff725e] md:border-0 transition-all ease-in duration-500 ${open ? "top-[-490px] opacity-100 " : "top-[4.7rem] "}`  }>
              <ul className="md:flex gap-8">
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/"
                    className={page === "home" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium md:text-lg text-gray-600 ">
                      Home
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                <div className="nav-link relative group">
                  <Link
                    to="/services"
                    className={page === "services" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium md:text-lg text-gray-600 ">
                      Services
                    </p>
                  </Link>
                  <div
                      className="items-center absolute border border-t-0 rounded-b-lg 
                   bg-white px-4 py-3 w-96 invisible group-hover:visible z-10"
                    >
                      <Link
                        to="/software-development"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer flex gap-3 items-center whitespace-nowrap px-3 text-gray-500 hover:text-gray-600 font-mediu">
                          <span><BsArrowRightShort className="text-[#ff725e]" /></span>
                        Software Development
                        </p>
                      </Link>
                      <Link
                        to="/mobile-app-development"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer flex gap-3 items-center whitespace-nowrap px-3 text-gray-500 hover:text-gray-600 font-mediu">
                        <span><BsArrowRightShort className="text-[#ff725e]" /></span>

                        Mobile App Development
                        </p>
                      </Link>

                      <Link
                        to="/web-development"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer flex gap-3 items-center whitespace-nowrap px-3 text-gray-500 hover:text-gray-600 font-mediu">
                        <span><BsArrowRightShort className="text-[#ff725e]" /></span>

                        Web Development Services

                        </p>
                      </Link>
                      <Link
                        to="/social-media-management"
                        className="px-0 py-2 block hover:font-semibold"
                      >
                        <p className="cursor-pointer flex gap-3 items-center whitespace-nowrap px-3 text-gray-500 hover:text-gray-600 font-mediu">
                        <span><BsArrowRightShort className="text-[#ff725e]" /></span>

                        Social Media Management

                        </p>
                      </Link>
                      
                    </div>
                  </div>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/approach"
                    className={page === "approach" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium md:text-lg text-gray-600 ">
                      Approach
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/writings"
                    className={page === "writings" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium md:text-lg text-gray-600 ">
                      Writings
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/about"
                    className={page === "about" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium md:text-lg text-gray-600 ">
                      About Us
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/contact"
                    className={page === "contact" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium md:text-lg text-gray-600 ">
                      Contact
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
export default Navbar;
