import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import ScrollToTop from "../../ScrollToTop";
import backgroundImage from "../../../assets/images/wave-haikei.svg";
// import ncdc from '../../../assets/images/wave-haikei.svg'
import { format } from "date-fns";

const Footer = () => {
  const [currentYear, setCurrentYear] = React.useState("");

  useEffect(() => {
    const year = new Date();
    const formattedYear = format(year, "yyyy");

    setCurrentYear(formattedYear);
  }, [setCurrentYear]);

  return (
    <div className="mt-24 md:mt-0">
      <div
        style={{
          backgroundAttachment: "local",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
        className="pb-7" 
      > 

      <div className="">
        <div className="px-2 pb-6 md:pb-12 ">
          <div className="flex flex-col md:flex-row w-full gap-6 ">
            <div className="md:-rotate-90 flex items-center md:justify-center md:w-3/12">
              <h3 className="uppercase font-semibold tracking-jast text-4xl text-zinc-700 rotate-0">
                Jasttech
              </h3>
            </div>
            <div className="md:w-6/12 md:pt-12 ">
              <p className="text-6xl md:text-7xl tracking-jast font-semibold md:font-medium text-zinc-700 leading-[1.1] ">
                You only get one chance to make a first impression.
              </p>
              <p className="text-2xl mt-5 md:tracking-tighter font-semibold md:font-medium text-zinc-700 ">
                We can make it an awesome one.
              </p>
            </div>
            <div className="md:w-4/12 md:pt-12 ">
              <h5 className="text-2xl mb-2 md:mb-0 font-semibold tracking-tight text-zinc-700">
                Useful Links
              </h5>
              <div className="flex flex-col gap-y-3 ml-3 font-normal text-lg">
                <Link
                  to="/services"
                  className="font-medium text-gray-800 cursor-pointer"
                >
                  Services
                </Link>
                <Link
                  to="/about"
                  className="font-medium text-gray-800 cursor-pointer"
                >
                  About Us
                </Link>
                <Link
                  to="/contact"
                  className="font-medium text-gray-800 cursor-pointer"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="md:mx-4 px-2 ">
          <div className="md:mx-12 border-t">
            <h5 className="text-left  text-white font-semibold tracking-tight text-sm  py-4 ">
              {currentYear} . Property of Jast Tech Ltd . Unless proven
              otherwise.
            </h5>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Footer;
