import React from "react";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import service from "../../../assets/images/service.svg";
import mobile from "../../../assets/images/Services/mobile.svg";
import software from "../../../assets/images/Services/software.svg";
import social from "../../../assets/images/Services/social.svg";
import web from "../../../assets/images/Services/web.svg";

const Services = () => {
  return (
    <div className=" md:mx-12 md:mt-24 mt-44">
      <div className="mx-4 px-2">
        <div className=" flex flex-col md:flex-row w-full items-center ">
          <div className=" md:w-2/4 ">
            <h3 className="tracking-tight font-semibold text-[2.7rem] text-[#ff725e]">
              Services
            </h3>
            <p class="leading-[1.5] font-medium text-2xl text-gray-800">
              Expertise, thorough knowledge and firsthand experience along with
              strong Industry specific research skills help us in analyzing,
              designing and implementing world class solutions.
            </p>
          </div>
          <div className="md:w-2/4 flex w-full justify-end">
            <img
              src={service}
              alt="services"
              className="md:w-10/12   object-cover object-center"
            />
          </div>
        </div>

        <div className=" mt-20 mb-20 ">
          <div class=" grid md:grid-cols-4 gap-5">
            <Link to="software-development ">
              <div className=" flex shadow-xl items-center justify-center">
                <div className="relative overflow-hidden w-full rounded-md border ">
                  <div className="">
                    <div className="">
                      <img
                        className="w-full object-cover"
                        src={software}
                        alt=""
                      />
                    </div>
                    <div className="py-3">
                      <h5 className="text-center text-[#ff725e] font-semibold text-sm uppercase mb-2">
                        Software Development
                      </h5>
                    </div>
                  </div>
                  <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-90 transition duration-300 ease-in-out bg-black">
                    <h1 className="text-base font-medium text-white py-12 px-10">
                      Seek ways to reduce costs, improve your business processes
                      and add value to your marketing and communications
                      strategies.
                    </h1> 
                  </div>
                </div> 
              </div>
            </Link>

            <Link to="mobile-app-development">
              <div className="flex shadow-xl items-center justify-center">
                <div className="relative overflow-hidden w-full  rounded-md border ">
                  <div>
                    <div>
                      <img
                        className="w-full object-cover"
                        src={mobile}
                        alt=""
                      />
                    </div>
                    <div className="py-3">
                      <h5 className="text-center text-[#ff725e] font-semibold text-sm uppercase mb-2">
                        Mobile App Development
                      </h5>
                    </div>
                  </div>
                  <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-90 transition duration-300 ease-in-out bg-black">
                    <h1 className="text-base font-medium text-white py-12 px-10">
                      With Mobile technology taking center stage in today’s
                      world, organizations are keen on making significant
                      advancements in the mobile space.
                    </h1>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="web-development">
              <div className="flex shadow-xl items-center justify-center">
                <div className="relative overflow-hidden w-full  rounded-md border ">
                  <div>
                    <div>
                      <img className="w-full object-cover" src={web} alt="" />
                    </div>
                    <div className="py-3">
                      <h5 className="text-center text-[#ff725e] font-semibold text-sm uppercase mb-2">
                        Web Development Services
                      </h5>
                    </div>
                  </div>
                  <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-90 transition duration-300 ease-in-out bg-black">
                    <h1 className="text-base font-medium text-white py-12 px-10">
                      A strong online presence is an effective medium of
                      displaying your product and services among your target
                      audience.
                    </h1>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="social-media-management">
              <div className="flex shadow-xl items-center justify-center">
                <div className="relative overflow-hidden w-full rounded-md border ">
                  <div>
                    <div>
                      <img
                        className="w-full object-cover"
                        src={social}
                        alt=""
                      />
                    </div>
                    <div className="py-3">
                      <h5 className="text-center text-[#ff725e] font-semibold text-sm uppercase mb-2">
                        Social Media Management
                      </h5>
                    </div>
                  </div>
                  <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-90 transition duration-300 ease-in-out bg-black">
                    <h1 className="text-base font-medium text-white py-12 px-10">
                      Social media is a powerful tool for marketing and
                      communication. It allows you to reach a wide audience and
                      increase your brand visibility.
                    </h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="md:mx-12 my-24 w-full">
        <div className="md:mx-12 mx-6 border-l-[10px] border-[#ff725e] md:w-7/12">
          <p className="text-[2.1rem] leading-[1.3] px-5 font-semibold text-[#ff725e]">
            Looking for our expert software development services?
          </p>

          <Link to="/contact">
            <div className="flex items-center mt-2 ml-5 w-fit gap-2 text-[#ff725e] hover:text-gray-800 hover:gap-1 ease-in duration-150   ">
              <p className="pl-5 pr-2 text-base text-gray-800 font-medium">
                Talk To Our Experts
              </p>
              <span className="">
                <BsFillArrowRightCircleFill className="text-[2.5rem]   " />
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
