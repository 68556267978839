import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Edgar from "../../../../assets/images/Team/Edgar.jpg";
import Hakeem from "../../../../assets/images/Team/Hakeem.jpeg";
import Proton from "../../../../assets/images/Team/Proton.jpg";
import Robert from '../../../../assets/images/Team/Robert.jpeg'
import Babe from "../../../../assets/images/Team/babe.png"; 
import Birungi from "../../../../assets/images/Team/Birungi.jpeg"; 
//import project_person from "../assets/images/project_person1.png";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

const Project = () => {
  const projects = [
    {
      img: Edgar,
      name: "Developer",
      github_link: "https://github.com/Emon-Adrian",
      
    },
    {
      img: Hakeem,
      name: "Counsel",
      github_link: "https://github.com/Emon-Adrian",
      
    },
    {
      img: Proton,
      name: "UI/UX Designer",
      github_link: "https://github.com/Emon-Adrian",
      
    },
    {
      img: Hakeem,
      name: "React Nav",
      github_link:
        "https://github.com/Emon-Adrian",
      
    },
    {
      img: Robert,
      name: "Ithilebu Robert",
      github_link: "https://github.com/Emon-Adrian",
     
    },
    {
      img: Birungi,
      name: "Social Media Specialist",
      github_link: "https://github.com/Emon-Adrian",
     
    },
  ];
  return (
    <section id="projects" className="py-10 text-white">
      <div className="text-center">
        <h3 className="text-4xl font-semibold">
          My <span className="text-[#ff725e]">The Team</span>
        </h3>
        <p className="text-gray-400 mt-3 text-lg">Meet the dedicated team of directors.</p>
      </div>
      <br />
      <div className="flex max-w-6xl gap-6 px-5 mx-auto items-center relative">
        <div className="lg:w-2/3 w-full">
          <Swiper
            slidesPerview={1.2}
            spaceBetween={20}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
            }}
            loop={true}
            autoplay={{
              delay: 3000,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
          >
            {projects.map((person_info, i) => (
              <SwiperSlide key={i}>
                <div className="h-fit w-full p-4 bg-[#ff725e] rounded-xl">
                  <img src={person_info.img} alt="" className="rounded-lg" />
                  <h3 className="text-xl my-4">{person_info.name}</h3>
                  <div className="flex gap-3">
                    <a
                      href={person_info.github_link}
                      target="_blank"
                      className="text-cyan-600 bg-gray-800 px-2 py-1 inline-block"
                    >
                      Github
                    </a>
                   
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="lg:block  hidden">
          <img src={Babe} alt=""  />
        </div>
      </div>
    </section>
  );
};

export default Project;