import React from "react";
import Form from "../../../components/Form/Form";
import FAQs from "./components/FAQs";
import { Link } from "react-router-dom";
import ts from "../../../assets/images/Developmentfocus-pana.svg";
import digital from "../../../assets/images/Partnership-pana.svg";
import remote from "../../../assets/images/Dataextraction-pana.svg";
import { BsArrowRight } from "react-icons/bs";
import formImage from "../../../assets/images/bg.png";

const Home = () => {
  return (
    <div className="">
      <div className="py-4 md:mx-12 mt-16 md:mt-24">
        <div className="mx-4 my-6 md:mb-24 flex flex-col md:flex-row">
          <div className=" px-2 md:w-8/12 w-full ">
            <p className=" text-5xl md:text-[4.2rem] leading-jast font-semibold text-gray-800 tracking-tighter ">
              We provide{" "}
              <span className="text-[#ff725e]">custom tech solutions</span> that
              help your business grow!
            </p>
            <span className="mt-14 flex flex-col md:flex-row w-full items-centers px-2 gap-5 ">
              <p className="px-0 font-semibold md:w-6/12 text-gray-700">
                Our services are at a better, but not any price!
              </p>
              <Link
                to="/contact"
                className="inline-flex gap-4 h-12 items-center text-white w-8/12 md:w-fit rounded-full bg-[#ff725e] px-3 md:px-5 py-9 font-semibold text-center"
              >
                Schedule a meeting{" "}
                <BsArrowRight color="white" className="font-semibold" />
              </Link>
            </span>
          </div>
          <div className="md:w-5/12">
            <Link to="/">
              <img
                className="h-full w-full"
                src={ts}
                alt="Woman workcationing on the beach"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="md:mb-32">
        <div className="grid md:grid-cols-3 border border-black md:h-80 ">
          <div className="p-12 font-medium text-3xl tracking-jast ">
            Jast Tech Ltd is a private technology company providing
            competitively priced outsourcing services to companies and
            businesses in Uganda.
          </div>
          <div className="bg-gray-600 text-white p-12 tracking-jast font-medium text-3xl">
            Our objective is to become a leading company which facilitates,
            enhances and provides measurable business value through most
            effective uses of Technology and Resources to companies nationwide
          </div>
          <div className="p-12 font-medium text-3xl tracking-jast">
            We set high quality standards for design for every business, small
            to large scale.
          </div>
        </div>
      </div>
      <div className="md:mx-16">
        <div className="px-2 mt-44 flex flex-col md:flex-row w-full justify-center items-center">
          <div className="md:w-7/12 p-2">
            <p className="text-3xl font-semibold tracking-tight text-[#ff725e] ">
              We Build Effective Platforms
            </p>
            <p className="text-3xl font-semibold tracking-tight text-[#ff725e] ">
              for every level of business
            </p>
            <span className="mt-6 flex ">
              <p className="px-0 font-medium">
                We serve clients at every level of their businesses, in whatever
                capacity we can be most useful, whether as a trusted advisor to
                top management. We strive to build a relationship of trust with
                every client, for the long-term.
              </p>
            </span>
          </div>
          <div className="w-96 md:w-5/12 md:p-2 ">
            <img className="h-full w-full" src={remote} alt="analysis robot" />
          </div>
        </div>
      </div>

      <div className="md:mx-16">
        <div className="px-2 mt-24 flex  flex-col-reverse md:flex-row w-full justify-center items-center">
          <div className="w-96 md:w-5/12 md:p-2 ">
            <img
              className="h-full w-full"
              src={digital}
              alt="digital handshake"
            />
          </div>

          <div className="md:w-7/12 p-2">
            <p className="text-3xl font-semibold tracking-tight text-[#ff725e] ">
              Let us be your Digital Transformation partner, we won't abandon
              you along the way - from initial planning to delivery, and
              further.
            </p>
            <span className="mt-6 flex ">
              <p className="px-0 font-medium">
                Our dedicated team of expert developers create bespoke software
                products, applications & operating systems for SMEs, enterprise,
                not-for-profit, government and funded start-ups using updated
                technology stacks.
              </p>
            </span> 
          </div>
        </div>
      </div>

      <div className="md:mx-16 p-2 mt-12">
        <div className="mt-4 flex flex-col md:flex-row gap-2 mad:gap-0 justify-between text-[#ff725e]">
          <h2 className="text-3xl font-semibold text-[#ff725e] tracking-tight">
            What We Offer
          </h2>
          <Link
            to="/services"
            className="md:text-lg font-semibold tracking-tight md:gap-3 flex items-center cursor-pointer hover:animate-pulse"
          >
            View more services{" "}
            <BsArrowRight
              color="#ff725e"
              className="hover:animate-pulse"
            />
          </Link>
        </div>
        <p className="mt-10 font-medium tracking-tight">
          Our approach to business is simple, We provide quality and timely
          solutions that enable you improve your business. Through active
          research and insight, we strategize with our customers in devising
          cost efficient solutions and deliver them using the best possible
          technologies.
        </p>
      </div>
      <div className="mx-2">
        <div className="md:mx-16 mt-6 flex flex-col md:flex-row border border-slate-900">
          <div className="justify-center flex flex-col  p-12 bg-[#ff725e] md:border-r-2 border-slate-900">
            <h4 className="px-4 text-3xl font-semibold tracking-jast text-slate-200">
              Website Design &
            </h4>
            <h4 className="px-4 text-3xl font-semibold tracking-jast text-slate-200">
              Development
            </h4>
            <p className="mt-10 px-4 mb-8 text-base text-white font-semibold leading-4  ">
              Websites have become one of the most important marketing & sales
              tools and that’s why we take care of all aspects of web design for
              our clients
            </p>
          </div>
          <div className=" p-10">
            <p className="text-3xl text-gray-800 font-medium tracking-jast">
              Mobile Application Development
            </p>
            <span className="mt-10 flex gap-10">
              <p className="px-0 text-gray-800  font-medium">
                Mobile technology gives businesses the opportunity to stay
                connected to customers almost all the time, enterprises are
                moving beyond the desktop world to keep attuned to their
                customer’s needs.
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div
          style={{
            backgroundAttachment: "fixed",
            backgroundImage: `url(${formImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div>
            <Form />
          </div>
        </div>
      </div>
      <div className="mt-20 md:mx-12 mb-5">
        <div className="md:mx-4 mx-2">
          <FAQs />
        </div>
      </div>
    </div>
  );
};

export default Home;
