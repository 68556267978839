import React from "react";
import {MdKeyboardArrowRight} from "react-icons/md"

const Form = () => {
  return (
    <div className="container w-full px-2 md:px-5 py-10 mx-auto">
      <div className="md:w-7/12 mx-auto bg-white shadow-md px-6 md:px-8 py-10 mb-4">
        <div className="my-3 mb-5">
          <h1 className="text-[#ff725e] text-3xl font-semibold tracking-tighter">
            We are Jast Tech Limited
          </h1>
          <p className="text-gray-700 font-semibold px-1">
            we build Software for the future!
          </p>
        </div>
        <form className=" ">
          <div className="mb-3">
            <label
              className="block text-gray-700 font-semibold mb-2"
              for="username"
            >
              Name
            </label>
            <input
              className=" font-semibold appearance-none border w-full py-6 px-3 text-gray-700 leading-tight focus:outline-sky-200  "
              id="username"
              type="text"
              placeholder="Mine is Jast Tech, what's yours?"
            />
          </div>
          <div className="mb-3">
            <label
              className="block text-gray-700  font-semibold mb-2"
              for="email"
            >
              Email Address
            </label>
            <input
              className=" font-semibold appearance-none border w-full py-6 px-3 text-gray-700 mb-3 leading-tight focus:outline-sky-200  "
              id="email"
              type="email"
              placeholder="Something like jast-tech@gmail.com "
            />
          </div>
          <div className="mb-3">
            <label
              className="block text-gray-700  font-semibold mb-2"
              for="text"
            >
              Budget (if any)
            </label>
            <input
              className=" font-semibold appearance-none border w-full py-6 px-3 text-gray-700 mb-3 leading-tight focus:outline-sky-200  "
              id="text"
              type="text"
              placeholder="We can come to an arrangement..."
            />
            <div className="flex items-center">
              <MdKeyboardArrowRight className="text-xl text-gray-700 italic" />
              <span className="text-xs font-semibold text-gray-700 italic">

            Article from Webfx.com that you can refer to for budget estimation.
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label
              className="block text-gray-700  font-semibold mb-2"
              for="text"
            >
              Description / Message /feedback
            </label>
            <textarea
              className=" font-semibold appearance-none border  w-full py-6 px-3 text-gray-700 mb-3 leading-tight focus:outline-sky-200  "
              id="text"
              type="text"
              placeholder="eg. I want a mobile application that can do A, B C and also D"
            />
          </div>
          <button className="bg-[#ff725e] text-white font-semibold w-full py-4">
            Submit
          </button>
          <p className="mt-3 text-gray-500 font-semibold text-sm">
            <span className="text-gray-400 font-semibold text-sm">
              Fast Inquiries:
            </span>{" "}
            +(256) 703040046
          </p>
        </form>
      </div>
    </div>
  );
};

export default Form;
