
import React, { useState } from 'react'
import approach from '../../../assets/images/approach.svg'
import quality from '../../../assets/images/quality.svg'
import time from '../../../assets/images/time.svg'
import pricing from '../../../assets/images/pricing.svg'



const Approach = () => {
  return (
    <section>
      <div className="container  mt-28">
        <div className="grid grid-cols-2 gap-14 py-4 mx-20 mt-24 items-center">
          <div className=''>
            <span className='text-4xl text-[#ff725e]'>Our Approach</span>
            <p>We'll deliver your software project quickly, efficiently and on-budget using our field-proven, agile processes.</p>
          </div>
          <div>
            <br />
            <img
              src={approach}
              alt=""
              width="100%"
            />
          </div>
        </div>
        <br />

        <div className="bg-[#ff725e] text-center h-96 flex items-center justify-center w-screen">
          <div className=" ">
            <span className="text-2xl text-[#ffffff]">
              What makes use
              <br />
              <span className="text-7xl text-[#ffffff] font=[Proxima Nova] "> The premier choice! </span>
            </span>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="py-4 mx-20 mt-10">
          <div className="grid grid-cols-2 gap-9">
            <div className="">
              <span className="text-2xl font-bold text-[rgb(255,114,94)]"> 1. OUR ROAD MAP </span>
              <br />
              <span className="font-semibold text-[#212529]">
                Our Roadmap to development is a collection of eight steps,
                each of which has it's own significance.
                <ul className="ml-9 list-disc font-semibold">
                  <li>Data mining</li>
                  <li>Forming a sketch</li>
                  <li>Designing</li>
                  <li>Make a blue print</li>
                  <li>Construct</li>
                  <li>Testing</li>
                  <li>Deployment</li>
                  <li>Maintanance & Upgrade</li>
                </ul>
              </span>
            </div>
            <img
              src={approach}
              alt=""
              width="100%"
              className="img-fluid"
              loading="lazy" />
          </div>
        </div>
        <div className="py-4 mx-20  mt-24 grid grid-cols-2 gap-9 ">
          <img src={quality} alt=''
            width="100%" class="img-fluid -mt-24" loading="lazy" />
          <div className="-ml-14">
            <span className="text-2xl font-bold text-[#92e3a9]"> 2.COMMITMENT TO QUALITY </span>
            <br />
            <span className="font-semibold text-[#212529]"> We believe in Quality service and our approach has been designed to provide the same. We listen carefully to our clients and provide space, time and materials according to agreement for the project. We are proud of our Technical and Creative capability and this emerges from taking time to get it right. Our Quality Assurance Department tests throughout the process to ensure that the project will be successful. </span>
          </div>
        </div>

        <div className='py-4 mx-20 mt-24'>
          <div className="grid grid-cols-2 gap-9">
            <div className=''>
              <span className="text-2xl font-bold text-[rgb(255,114,94)]"> 3. ON TIME DELIVERY </span>
              <br />
              <span className="font-semibold text-[#212529]"> We invest the time up-front to clearly understand your needs and then manage projects to ensure they are delivered on time and within budget. This methodology expedites rapid successful implementation, limits uncertainty and keeps the customer always aware of development progress at our end. </span>
            </div>
            <img
              src={time}
              alt=""
              width="100%"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>

        <div className="py-4 mx-20 mt-24 grid grid-cols-2 gap-10">
          <img src={pricing}
            alt="" width="100%" class="img-fluid -mt-24" loading="lazy" />
          <div className="-ml-14">
            <span className="text-2xl font-bold text-[#92e3a9]"> 4.COMPETITIVE PRICING </span>
            <br />
            <span className="font-semibold text-[#212529]"> We render services to our clients at a very competitive price and depending on the type of client engagement we offer them the most suitable pricing model structure which could either be fixed price based, time and effort based or a dedicated offshore team model. </span>
          </div>
        </div>



      </div>

    </section>

  );
};

export default Approach
