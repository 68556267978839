import React, { useState } from 'react'
import Slider from './components/Slider'
import about from '../../../assets/images/about.svg'

const About = () => {
    return (
        <div className='py-4 mx-20 mt-24'>
            <div className=" container mt-36 ">
                <div className="grid grid-cols-2">
                    <div >
                        <span className='font-semibold text-[hsl(7,100%,68%)] text-4xl'>Get to know us more</span>

                        <br />
                        <br />

                        <span className="font-semibold text-xl text-[#343a40]">A Software Development, Data Analysis and Web design & development company providing competitively priced
                            outsourcing services to companies and businesses</span>
                    </div>
                    <img src={about}
                        alt="" className="-mt-24 mr-20" />

                </div>

                <br />
                <br />


                <><span className="text-[hsl(7,100%,68%)] font-medium text-xl">We are a team of highly skilled intellectual personnel, most having substantial work
                    experience in diverse areas of Information Technology.</span>

                    <br>
                    </br>

                    <span className="font-medium">Our augmenting approach is derived from the
                        experience of our team members. As a result of their combined
                        practical business experience with strong technology delivery capabilities, we are able to comprehend the client's business requirements, propose IT and creative strategies in line with the vision of the business and execute the business plan by building or implementing high quality scalable business
                        solutions through the use of latest cutting edge Technologies.
                    </span>

                    <br />
                    <br />
                    <br />

                    </>
            </div>
            <Slider />
        </div>

    )
}

export default About