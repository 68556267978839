import React from 'react'
import {BrowserRouter, Link, Route, Routes } from 'react-router-dom'

import Home from'../features/misc/Home/Home'
import Approach from '../features/misc/Approach/Approach'
import Contact from'../features/misc/Contact/Contact'
import Writings from'../features/misc/Writings/Writings'
import Services from '../features/misc/Services/Services'
import About from '../features/misc/About Us/About'
import { MainLayout } from '../components/Layout/MainLayout'
import Navbar from '../components/Layout/components/Navbar'
import Footer from '../components/Layout/components/Footer'

const AppRoutes = () => {
  return (
    <BrowserRouter>
       <Navbar/>
       <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>               
        <Route path='/approach' element={<Approach/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/writings' element={<Writings/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='*' element={<Services/>}/>
       </Routes>
       <Footer/>
    </BrowserRouter>
  )
}
export default AppRoutes


