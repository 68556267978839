export const faqs = [
    {
        id: 1,
        qn: "How much do your managed IT services cost?",
        ans: "It depends on the number of users, computers, and servers you have in your workplace. After speaking with you about your  requirements, our team will put together a custom proposal that covers all of your equipment and provides the services needed to keep your business running smoothly on a daily basis."
    }, 
    {
        id: 2,
        qn: "How do we get hold of you when we have an issue?",
        ans: "If you’re a current client and have a tech problem, you can call the main office number and speak with an engineer right away. You can also email our engineering team at  engineering@jast-tech.com. If your issue arises after hours and you are a contracted customer, you can call the after-hours support line and an engineer will answer the phone (or call you right back if you leave a voicemail message)."
    }, 
    {
        id: 3,
        qn: "I can handle my IT in-house. Why would I want to pay you to do it?",
        ans: "Sure thing __ adding another line item to your business budget  can be unnerving. But the truth is, handling your own tech issues is already costing you. That’s because your time is valuable. Could you be earning more money every day by doing  what you do best. Come on, let Jast Tech handle the rest. Contact us today to see what we can do for your business productivity — and your bottom line. "
    }, 

    {
        id: 4,
        qn: "How can Managed IT Services help my business?",
        ans: "How can Managed IT Services help my business? Managed IT services help businesses prevent costly downtime by proactively monitoring your network for issues and fixing them. Since your network is remotely monitored, your risk of virus, breaches and downtime decreases. The flat fee can also save hundreds or even  thousands of dollars compared to the high cost of break-fix IT services."
    }, 

    {
        id: 5,
        qn: "Who Benefits from Managed IT Services?",
        ans: "For small to mid-sized companies, a Managed IT Service Provider  will often act as the outsource IT staff or supplement a small in-house team. Larger companies and enterprises often work with  Managed IT Service Providers to help fill in gaps, complete projects, perform migrations or augment an in-house IT staff."
    }, 

]