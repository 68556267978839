import React from 'react'
//import Layout from '../../components/Layout'

const Writings = () => {
  return (
 <div class="flex  flex-col items-center  text-center" >
  <div style={{backgroundColor:'#D9D9D9', width:'100%'}}>
    <h1 class="text-4xl mt-3">Writings</h1>
    <h5 class="mb-8">The latest news to improve design and drive business strategy</h5>
  <div class="grid md:grid-cols-2 sm:grid-cols-1 items-center justify-center gap-0 mb-5">
    <div class="w-96 mx-auto rounded-lg border border-gray-600 p-1 shadow-2xl">
      <img class="rounded-tl-lg rounded-tr-lg object-cover" src="https://www.kindacode.com/wp-content/uploads/2022/06/computer-example.jpg" />
      <div class="mt-3 text-left gap-2">
        <span class="rounded-full bg-gray-800 p-2 text-xs text-sky-50">Sales Ops</span>
        <span class="text-xs ml-1">8 min to read</span>
      </div>
      <p class="mt-2 text-left text-lg">The 3 Best Practices in Sales Prospecting</p>
      <div class="mt-3 flex items-center gap-1 text-left">
        <img src="https://cdn5.vectorstock.com/i/thumb-large/45/59/profile-photo-placeholder-icon-design-in-gray-vector-37114559.jpg" class="h-10 w-10 rounded-full border border-gray-700" />
        <div class="">
          <h5 class="font-bold text-xs">Angel N</h5>
          <h5 class="font-light text-xs">20 apr 2022</h5>
        </div>
      </div>
    </div>
    <div class="space-y-2">
      <div class="flex gap-1">
        <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80" class="w-36" />
        <div class="mt-3 text-left">
          <span class="rounded-full bg-gray-800 p-2 text-xs text-sky-50">Sales Ops</span>
          <span class="text-xs ml-1">8 min to read</span>
          <p class="mt-2 text-left text-sm">The 3 Best Practices in Sales Prospecting</p>
          <div class="mt-3 flex items-center gap-1 text-left">
            <img src="https://cdn5.vectorstock.com/i/thumb-large/45/59/profile-photo-placeholder-icon-design-in-gray-vector-37114559.jpg" class="h-10 w-10 rounded-full border border-gray-700" />
            <div class="text-sm ">
              <h5 class="font-bold text-xs">Angel N</h5>
              <h5 class="font-light text-xs">20 apr 2022</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-1">
        <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80" class="w-36" />
        <div class="mt-3 text-left">
          <span class="rounded-full bg-gray-800 p-2 text-xs text-sky-50">Sales Ops</span>
          <span class="text-xs ml-1">8 min to read</span>
          <p class="mt-2 text-left text-sm">The 3 Best Practices in Sales Prospecting</p>
          <div class="mt-3 flex items-center gap-1 text-left">
            <img src="https://cdn5.vectorstock.com/i/thumb-large/45/59/profile-photo-placeholder-icon-design-in-gray-vector-37114559.jpg" class="h-10 w-10 rounded-full border border-gray-700" />
            <div class="text-sm">
              <h5 class="font-bold text-xs">Angel N</h5>
              <h5 class="font-light text-xs">20 apr 2022</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-1">
        <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80" class="w-36" />
        <div class="mt-3 text-left">
          <span class="rounded-full bg-gray-800 p-2 text-xs text-sky-50">Sales Ops</span>
          <span class="text-xs ml-1">8 min to read</span>
          <p class="mt-2 text-left text-sm">The 3 Best Practices in Sales Prospecting</p>
          <div class="mt-3 flex items-center gap-1 text-left">
            <img src="https://cdn5.vectorstock.com/i/thumb-large/45/59/profile-photo-placeholder-icon-design-in-gray-vector-37114559.jpg" class="h-10 w-10 rounded-full border border-gray-700" />
            <div class="text-sm">
              <h5 class="font-bold text-xs">Angel N</h5>
              <h5 class="font-light text-xs">20 apr 2022</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
 


  <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-9 p-2" >
    <div class="text-left">
      <h2 class="text text-base mb-2">All Blog Posts</h2>
      <div class="space-y-2">
        <div class="flex gap-1">
          <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80" class="w-36" />
          <div class="mt-3 text-left">
            <span class="rounded-full bg-gray-800 p-2 text-xs text-sky-50">Sales Ops</span>
            <span class="text-xs ml-1">8 min to read</span>
            <p class="mt-2 text-left text-sm">The 3 Best Practices in Sales Prospecting</p>
            <div class="mt-3 flex items-center gap-1 text-left">
              <img src="https://cdn5.vectorstock.com/i/thumb-large/45/59/profile-photo-placeholder-icon-design-in-gray-vector-37114559.jpg" class="h-10 w-10 rounded-full border border-gray-700" />
              <div class="text-sm">
                <h5 class="font-bold text-xs">Angel N</h5>
                <h5 class="font-light text-xs">20 apr 2022</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-1">
          <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80" class="w-36" />
          <div class="mt-3 text-left">
            <span class="rounded-full bg-gray-800 p-2 text-xs text-sky-50">Sales Ops</span>
            <span class="text-xs ml-1">8 min to read</span>
            <p class="mt-2 text-left text-sm">The 3 Best Practices in Sales Prospecting</p>
            <div class="mt-3 flex items-center gap-1 text-left">
              <img src="https://cdn5.vectorstock.com/i/thumb-large/45/59/profile-photo-placeholder-icon-design-in-gray-vector-37114559.jpg" class="h-10 w-10 rounded-full border border-gray-700" />
              <div class="text-sm">
                <h5 class="font-bold text-xs">Angel N</h5>
                <h5 class="font-light text-xs">20 apr 2022</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-1">
          <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80" class="w-36" />
          <div class="mt-3 text-left">
            <span class="rounded-full bg-gray-800 p-2 text-xs text-sky-50">Sales Ops</span>
            <span class="text-xs ml-1">8 min to read</span>
            <p class="mt-2 text-left text-sm">The 3 Best Practices in Sales Prospecting</p>
            <div class="mt-3 flex items-center gap-1 text-left">
              <img src="https://cdn5.vectorstock.com/i/thumb-large/45/59/profile-photo-placeholder-icon-design-in-gray-vector-37114559.jpg" class="h-10 w-10 rounded-full border border-gray-700" />
              <div class="text-sm">
                <h5 class="font-bold text-xs">Angel N</h5>
                <h5 class="font-light text-xs">20 apr 2022</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-left">
        <h4 class="mb-2">Topics</h4>
      <div class="space-y-3 content-left"> 
      <div class='border border-gray-800 w-1/2 text-left px-2'>Sales</div>
      <div class='border border-gray-800 w-1/2 text-left px-2'>Sales</div>
      <div class='border border-gray-800 w-1/2 text-left px-2'>Sales</div>
      <div class='border border-gray-800 w-1/2 text-left px-2'>Sales</div>
      </div>
    </div>
  </div>
</div>
  )
}

export default Writings