import React, { useState } from 'react'
import Form from '../../../components/Form/Form';
import contact from '../../../assets/images/contact.svg'




const Contact = () => {
    return (


        <div className="mt-20 container px-5 py-24 mx-auto">
            <section className="text-gray-600 body-font">
                <div className="container px-5  mx-auto flex flex-wrap items-center">
                    <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                        <h1 className="title-font font-bold text-5xl text-[#ff725e]">Reach Out to us</h1>
                        <p className="leading-10 font-medium text-3xl text-gray-900 mt-4">We'd like to be your guide through your digital transformation journey. So let's start a conversation, please fill out the form below and you'll be called within 24 hours!</p>
                    </div>
                    <div className="lg:w-2/6 md:w-1/2 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                        <img className="w-full h-full" src={contact} alt="Woman workcationing on the beach" />
                    </div>
                </div>
            </section>
            <br />
            <br />

            <div />
            <Form />

            <br />

        </div>

    );






}

export default Contact